var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addActivity"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"活动名称","prop":"activityName"}},[_c('v-input',{attrs:{"disabled":"","width":250},model:{value:(_vm.form.activityName),callback:function ($$v) {_vm.$set(_vm.form, "activityName", $$v)},expression:"form.activityName"}})],1),_c('el-form-item',{attrs:{"label":"活动回顾文本","rules":[
        { required: false, message: '请输入活动回顾文本', trigger: 'blur' },
      ],"prop":"feedback"}},[_c('v-ueditor',{attrs:{"maxLength":20000,"isShowImg":false},model:{value:(_vm.form.feedback),callback:function ($$v) {_vm.$set(_vm.form, "feedback", $$v)},expression:"form.feedback"}})],1),_c('el-form-item',{attrs:{"label":"封面图片","rules":[
        {
          required: false,
          message: '请上传封面图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"fbCoverUrl"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.fbCoverUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "fbCoverUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "fbCoverUrl", $event)}}})],1),_c('el-form-item',{attrs:{"label":"活动回顾图片","rules":[
        {
          required: false,
          message: '请上传活动回顾图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"fbImageUrls"}},[_c('v-upload',{attrs:{"imgUrls":_vm.form.fbImageUrls},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "fbImageUrls", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "fbImageUrls", $event)}}})],1),_c('el-form-item',{attrs:{"label":"活动回顾视频","rules":[
        { required: false, message: '请上传活动回顾视频', trigger: 'blur' },
      ],"prop":"fbVideoUrl"}},[_c('v-videoUpload',{attrs:{"limit":1,"videoUrls":_vm.form.fbVideoUrl},on:{"update:videoUrls":function($event){return _vm.$set(_vm.form, "fbVideoUrl", $event)},"update:video-urls":function($event){return _vm.$set(_vm.form, "fbVideoUrl", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }